import React from 'react';
import moment from 'moment';

import * as S from "./styles";

const NewsCircleDate = ({ date }) => {
    const monthNames = [
        "Sty",
        "Lut",
        "Mar",
        "Kwi",
        "Maj",
        "Cze",
        "Lip",
        "Sie",
        "Wrz",
        "Paź",
        "Lis",
        "Gru",
    ];

    return (
        <S.DateContainer>
            <S.DateContent>
                <S.Day>
                    { moment(date).format("DD") }
                </S.Day>

                <S.Month>
                    { monthNames[moment(date).format("MM") - 1] }
                </S.Month>
            </S.DateContent>
        </S.DateContainer>
    );
};
 
export default NewsCircleDate;
