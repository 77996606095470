import styled from "styled-components";
import { breakpoints, colors } from "../../../styles/variables";

import { H2 } from "../../atoms/Typography/H2/styles";

export const NewsBlogLayout = styled.section`
    display: flex;
    max-width: 125rem;
    width: 100%;
    padding: 7rem 2.5rem;

    @media (min-width: ${ breakpoints.big }) {
        padding: 10rem 2.5rem;
    }
`;

export const Article = styled.article`
    width: 100%;
`;

export const NewsColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const AsideColumn = styled.aside`
    font-family: "Lato", sans-serif;
    display: none;
    flex-shrink: 0;
    flex-direction: column;
    width: 20rem;
    margin-left: 2.5rem;
    font-size: 2rem;
    line-height: 2.6rem;

    p {
        padding-bottom: 1.2rem;
    }

    @media (min-width: ${ breakpoints.medium }) {
        display: flex;
    }

    @media (min-width: ${ breakpoints.big }) {
        width: 27rem;
        margin-left: 3rem;
    }
`;

export const ImageContainer = styled.div`
    width: 100%;
    height: 24rem;
    position: relative;
    overflow: hidden;

    & > img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    @media (min-width: ${ breakpoints.small }) {
        height: ${({ singlePostImage }) => singlePostImage ? '40rem' : '32rem'};
    }

    @media (min-width: ${ breakpoints.big }) {
        height: ${({ singlePostImage }) => singlePostImage ? '50rem' : '40rem'};
    }
`;

export const NewsTitle = styled(H2)`
    text-align: left;
    margin-top: 3rem;
`;

export const NewsDate = styled.p`
    font-family: "Lato", sans-serif;
    padding: 1.6rem 0 2.8rem;
    font-size: 1.4rem;
    line-height: 1.4rem;
    color: ${ colors.ddGrey550 };
    border-bottom: .1rem solid ${ colors.ddGrey300 };
    margin-bottom: 2rem;
`;

export const NewsText = styled.p`
    font-family: "Lato", sans-serif;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: ${ colors.blackHighContrast };
    margin-bottom: 2rem;
`;

export const ButtonsContainer = styled.div`
    margin-bottom: 6.4rem;
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
`;
