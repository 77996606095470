export const NEWS_PAGE_CONFIG = {
    additionalContent: [
        {
            id: 1,
            text: "Na tej stronie publikowane są ważne wydarzenia z życia naszych placówek.",
        },
        {
            id: 2,
            text: "Jeśli chcesz wiedzieć na bieżąco co się dzieje u naszych wychowanków śledź nasze aktualności.",
        },
        {
            id: 3,
            text: "Jesteś tu pierwszy raz? Zachęcamy do obejrzenia starszych wpisów.",
        },
    ],
};