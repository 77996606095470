import React from 'react';

import * as S from "./styles";

import Loader from '../../atoms/Loader';
import NewsItem from './NewsItem';

const NewsPageTemplate = ({
    newsData,
    asideContent,
    isLoading,
}) => {
    // TODO: paginacja, podstrona aktualnosci jednej + galeria
    return (
        <S.NewsBlogLayout>
            <S.NewsColumn>
                {
                    !!isLoading ? (
                        <Loader />
                    ) : (
                        newsData.map(newsItem => (
                            <NewsItem
                                newsItemData={ newsItem }
                                key={ newsItem.uid }
                            />
                        ))
                    )
                }
            </S.NewsColumn>

            <S.AsideColumn>
                {
                    asideContent.map(({ id, text }) => (
                        <p key={ id }>
                            { text }
                        </p>
                    ))
                }
            </S.AsideColumn>
        </S.NewsBlogLayout>
    );
};
 
export default NewsPageTemplate;
