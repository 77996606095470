import React, { useState } from 'react';
import moment from 'moment';

import * as S from "./styles";

import NewsCircleDate from '../../molecules/NewsCircleDate';
import Button from '../../atoms/Button';
import { GalleryThumbnailsSection } from '../../sections';
import { getImage } from 'gatsby-plugin-image';

const NewsItem = ({
    newsItemData,
    showTrimmedText = true,
    showGallery = false,
    backButton = false,
}) => {
    const [isPageScrollLocked, setIsPageScrollLocked] = useState(false);

    moment.locale('pl', {
        months: 'styczeń_luty_marzec_kwiecień_maj_czerwiec_lipiec_sierpień_wrzesień_październik_listopad_grudzień',
    });

    const {
        uid,
        first_publication_date,
        news_title,
        news_text,
        news_image,
        news_gallery,
    } = newsItemData;

    const galleryImagesItems = news_gallery.map(({ news_gallery_image }, index) => {
        return {
            id: index + 1,
            name: news_gallery_image.alt,
            image: getImage(news_gallery_image.gatsbyImageData)
        }
    });

    const trimmedNewsText = news_text.length > 120 ?
        `${ news_text.substring(0, 120) }...` : 
        news_text;

    return (
        <S.Article>
            <S.ImageContainer singlePostImage>
                <img
                    src={ news_image.fluid.src }
                    alt={ news_image.alt }
                />

                <NewsCircleDate date={ first_publication_date } />
            </S.ImageContainer>

            <S.NewsTitle>
                { news_title }
            </S.NewsTitle>
            
            <S.NewsDate>
                { moment(first_publication_date).format("DD.MM.YYYY") }
            </S.NewsDate>

            <S.NewsText>
                { showTrimmedText ? trimmedNewsText : news_text }
            </S.NewsText>

            {
                showGallery && !!news_gallery.length &&
                    <GalleryThumbnailsSection 
                        imagesQuery={ galleryImagesItems } 
                        setIsPageScrollLocked={ setIsPageScrollLocked }
                        internal
                    />
            }            

            <S.ButtonsContainer>
                <Button href={ `/aktualnosci${ !!backButton ? "" : `/${ uid }` }` }>
                    { !!backButton ? "Powrót" : "Czytaj więcej" }
                </Button>
            </S.ButtonsContainer>
        </S.Article>
    );
};
 
export default NewsItem;
