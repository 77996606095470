import styled from "styled-components";
import { breakpoints, colors } from "../../../styles/variables";

export const DateContainer = styled.div`
    font-family: "Lato", sans-serif;
    position: absolute;
    top: 2rem;
    left: 2rem;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    background-color: ${ colors.white };
    display: flex;
    align-items: center;
    justify-content: center;

    @media (min-width: ${ breakpoints.medium }) {
        width: 8rem;
        height: 8rem;
    }
`;

export const DateContent = styled.p`
    display: flex;
    flex-direction: column;

    span {
        text-align: center;
    }
`;

export const Day = styled.span`
    font-size: 2rem;
    font-weight: 700;
    color: ${ colors.ddGreen200 };
    margin-bottom: .5rem;
`;

export const Month = styled.span`
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;
`;
