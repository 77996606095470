import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';

import SubsiteHeader from '../../components/molecules/SubsiteHeader';
import PageLayout from "../../components/layout/PageLayout";
import NewsPageTemplate from '../../components/templates/NewsPageTemplate';
import Seo from '../../components/seo';

import { cmsNewsDataMapper } from '../../utils/helpers';

import { NEWS_PAGE_CONFIG } from '../../content/news_content_config';

const NewsPage = ({ location, data }) => {
    const [isCmsDataLoading, setIsCmsDataLoading] = useState(true);
    const [cmsNewsData, setCmsNewsData] = useState([]);

    const { additionalContent } = NEWS_PAGE_CONFIG;

    useEffect(() => {
        setCmsNewsData(cmsNewsDataMapper(data));
        cmsNewsDataMapper(data)
        setIsCmsDataLoading(false);
    }, []);
    
    return (
        <>
            <Seo
                title="Aktualności | P.O.W. Ignaś i Helenka w Kaliskach"
                description="Bądź na bieżąco z wydarzeniami i aktualnoścami w Placówkach Opiekuńczo-Wychowawczych Ignaś i Helenka w Kaliskach."
            />
            
            <PageLayout
                location={ location }
            >
                <SubsiteHeader title="Wszystkie aktualności"/>

                <NewsPageTemplate
                    newsData={ cmsNewsData }
                    asideContent={ additionalContent }
                    isLoading={ isCmsDataLoading }
                />
            </PageLayout>
        </>
    );
};

export const query = graphql`
    query NewsAndEvenetsQuery {
        allPrismicNewsAndEvents(sort: {fields: first_publication_date, order: DESC}) {
            nodes {
                uid
                first_publication_date
                data {
                    news_date
                    news_title {
                        text
                    }
                    news_text {
                        text
                    }
                    news_gallery {
                        news_gallery_image {
                            alt
                            gatsbyImageData
                        }
                    }
                    news_main_image {
                        alt
                        fluid {
                            src
                        }
                    }
                }
            }
        }
    }
`;
 
export default NewsPage;
